import React from "react";

import Logo from "@/components/logo";
import MainButton from "@/components/pages/home/main-button";
import { ModeToggle } from "@/layouts/theme-toggle";
import Link from "next/link";
import { homePath } from "@/lib/utils/paths";

interface IProps {
  showFeatures?: boolean;
}

const HomeNavbar = ({ showFeatures = true }: IProps) => {
  return (
    <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
      <nav className="flex justify-between w-full items-center">
        <div className="flex gap-4 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-base lg:gap-6">
          <Link
            href={homePath}
            className="flex items-center gap-2 text-lg font-semibold md:text-base w-fit"
            shallow
          >
            <Logo />
          </Link>
          {showFeatures && (
            <a
              href="#features"
              className="text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap hidden md:inline"
            >
              Features
            </a>
          )}
          <a
            href="mailto:criss@usetopvoice.com"
            className="text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap hidden md:inline"
          >
            Contact
          </a>
        </div>
        <div className="flex flex-row gap-2">
          <div className="hidden sm:block">
            <MainButton size={"sm"} />
          </div>
          <ModeToggle />
        </div>
      </nav>
    </header>
  );
};

export default HomeNavbar;
