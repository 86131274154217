import { ReactNode } from "react";
import { useSession } from "next-auth/react";

import {
  CalendarCheck,
  FastForward,
  Lightbulb,
  Option,
  Play,
  Repeat,
} from "lucide-react";

import H2 from "@/components/ui/H2";
import { Separator } from "@/components/ui/separator";
import Logo from "@/components/logo";
import H4 from "@/components/ui/H4";
import MainButton from "@/components/pages/home/main-button";
import HomeNavbar from "@/components/pages/home/navbar";
import Link from "next/link";
import { privacyPolicyPath, termsConditionsPath } from "@/lib/utils/paths";
import { Button } from "@/components/ui/button";

const FEATURES = [
  {
    icon: () => <Lightbulb />,
    title: "Generate ideas",
    description:
      "Automatically come up with relevant ideas based on your expertise, skills, and experiences.",
  },
  {
    icon: () => <FastForward />,
    title: "From 0 to post",
    description:
      "Write amazing posts 12 times faster by starting from raw ideas and applying proven templates.",
  },
  {
    icon: () => <Repeat />,
    title: "Repurpose brand new",
    description:
      "With our advanced prompt techniques, you can transform any post into a completely new one (NOT JUST REPHRASING).",
  },
  {
    icon: () => <CalendarCheck />,
    title: "Automated scheduler",
    description:
      "Schedule on any day, at any time. We'll get you posted! This will help you stay consistent.",
  },
  {
    icon: () => <Option />,
    title: "Automated editing",
    description:
      "We've prepared editing options to enhance your posts according to your needs. Just press the button, and the option will be applied!",
  },
];

interface IFeatureProps {
  icon: () => ReactNode;
  title: string;
  description: string;
}

const Feature = ({ description, icon: Icon, title }: IFeatureProps) => {
  return (
    <div className="w-full max-w-[340px]">
      <div className="border border-foreground rounded-md flex justify-center items-center p-3 w-fit">
        <Icon />
      </div>
      <H4 className="font-semibold">{title}</H4>
      <p>{description}</p>
    </div>
  );
};

export default function Home() {
  const { data: session, status } = useSession();

  if (status === "loading") {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <HomeNavbar />
      <main className="flex flex-col gap-[100px] mt-[0px] md:mt-[60px] px-4">
        <section className="flex flex-col items-center gap-[40px]">
          <div className="space-y-8">
            <H2 className="text-center ">
              Skip the process -enjoy the results!
            </H2>
            <p className="text-2xl text-center max-w-[800px] mx-auto text-muted-foreground">
              What if I told you there is a way to automate the entire content
              creation process, and YOU can get it for FREE right at this
              moment?
            </p>
          </div>
          <div className="flex gap-4 flex-wrap justify-center">
            <MainButton hasArrow={true} size={"lg"} />
            <a
              href={"https://www.youtube.com/watch?v=6AzG-VQfFxo"}
              target="_blank"
            >
              <Button size={"lg"} variant={"outline"} className="gap-2">
                <Play />
                Watch tutorial
              </Button>
            </a>
          </div>
          <p className="text-center">
            (By clicking this button, you aggree to TopVoice&apos;s{" "}
            <Link href={termsConditionsPath} shallow className="underline">
              Terms and Conditions
            </Link>
            .<br />
            The{" "}
            <Link href={privacyPolicyPath} shallow className="underline">
              Privacy Policy
            </Link>{" "}
            is also available to the public.)
          </p>
          <img
            src="dark_landing_page.png"
            alt="mock image"
            className="max-w-[1200px] w-full rounded-md"
          />
        </section>
        <section
          id="features"
          className="flex flex-col items-center gap-[60px]"
        >
          <H2 className="text-center">Quick solutions, no stress</H2>
          <div className="grid gap-9 items-start justify-center md:grid-cols-2 md:grid-rows-3 lg:grid-cols-3 lg:grid-rows-2 grid-rows-5">
            {FEATURES.map(({ icon, title, description }) => (
              <Feature
                key={title}
                icon={icon}
                title={title}
                description={description}
              />
            ))}
          </div>
        </section>
        <footer>
          <Separator />
          <div className="flex flex-row justify-between  py-4">
            <Logo />
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <a
                href="mailto:criss@usetopvoice.com"
                className="text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap"
              >
                Contact
              </a>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
}
